import {useIntl} from 'react-intl'
import {NavLink, useLocation} from 'react-router-dom'
import {isFeatureEnabled, toAbsoluteUrl} from '../../../../_gori/helpers'
import {useEffect} from 'react'
import {FEATURES} from '../../../../_gori/constants/_features'
import {ROUTES} from '../../../../_gori/constants'

export function Header() {
  const intl = useIntl()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const {pathname} = useLocation()

  const toggleIntegrations = () => {
    const dd = document.getElementById('integrations-dropdown-desktop')
    dd?.classList.toggle('d-none')
    dd?.classList.toggle('d-flex')
  }

  const toggleMobileIntegrations = () => {
    const dd = document.getElementById('integrations-dropdown-mobile')
    dd?.classList.toggle('show')
  }

  const closeDTDropdown = () => {
    const dd = document.getElementById('integrations-dropdown-desktop')
    if (dd?.classList.contains('d-flex')) {
      dd.classList.remove('d-flex')
      dd.classList.add('d-none')
    }
  }

  const closeMobileDropdown = () => {
    const mobileDd = document.getElementById('integrations-dropdown-mobile')
    if (mobileDd?.classList.contains('show')) {
      mobileDd.classList.remove('show')
    }
  }

  useEffect(() => {
    const integrationActive = pathname.includes('integrations')
    const integrationsLinkItem = document.querySelector('.integrations-container .nav-link')
    const mobileDd = document.getElementById('integrations-dropdown-mobile')

    if (integrationActive) {
      integrationsLinkItem?.classList.add('active')
    }

    document.addEventListener('shown.bs.collapse', () => {
      if (pathname && pathname.includes('integrations') && !mobileDd?.classList.contains('show')) {
        toggleMobileIntegrations()
      }
    })

    return () => {
      document.removeEventListener('shown.bs.collapse', () => {
        // no-op, just to clean up the event listener
      })
    }
  }, [pathname])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.getElementById('integrations-dropdown-desktop')
      const mobileDropdown = document.getElementById('integrations-dropdown-mobile')

      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !event.target.closest('.integrations-container')
      ) {
        closeDTDropdown()
      }

      if (
        mobileDropdown &&
        !mobileDropdown.contains(event.target) &&
        !event.target.closest('.integrations-container-mobile')
      ) {
        closeMobileDropdown()
      }
    }

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        closeDTDropdown()
        closeMobileDropdown()
      }
    }

    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('keydown', handleEscKey)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [])

  return (
    <header className='public-header'>
      <nav className='navbar navbar-expand-md navbar-light bg-white fixed-top border-bottom shadow-sm'>
        <div className='container'>
          {/* Logo */}
          <NavLink
            to={isFeatureEnabled(FEATURES.LANDING_PAGE) ? '/landing' : '/'}
            className='navbar-brand p-0'
          >
            <img
              alt='ShipBae Powered By Gori.ai'
              src={toAbsoluteUrl('/media/logos/shipbae-gori-logo.svg')}
            />
          </NavLink>

          {/* Hamburger button */}
          <button
            className='navbar-toggler border-0'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          {/* Collapsible Menu */}
          <div className='collapse navbar-collapse mt-5 mt-md-0' id='navbarCollapse'>
            {/* Left side links */}
            <ul className='navbar-nav align-items-center me-auto mb-2 mb-md-0'>
              <li className='nav-item d-md-none'>
                <NavLink
                  to={'/product'}
                  className='nav-link py-2 py-md-0 px-0'
                  onClick={closeMobileDropdown}
                >
                  {intl.formatMessage({id: 'WHATS_SHIPBAE'})}
                </NavLink>
              </li>

              <li className='nav-item d-md-none'>
                <NavLink
                  to={'/pricing'}
                  className='nav-link py-2 py-md-0 px-0'
                  onClick={closeMobileDropdown}
                >
                  {intl.formatMessage({id: 'PRICING'})}
                </NavLink>
              </li>

              {/* Mobile Integrations */}
              <li className='nav-item d-md-none integrations-container-mobile position-relative'>
                <button
                  className='nav-link py-2 py-md-0 px-0 btn btn-link text-start'
                  onClick={toggleMobileIntegrations}
                >
                  {intl.formatMessage({id: 'INTEGRATION'})}
                  <img
                    className='ms-2 d-inline-block'
                    src='/media/gori/arrows/arrow_down_gray.svg'
                    alt='down-chevron'
                  />
                </button>

                <div
                  id='integrations-dropdown-mobile-container'
                  className='integration-dropdown-menu-container'
                >
                  <ul
                    id='integrations-dropdown-mobile'
                    className='integration-dropdown-menu nav flex-nowrap list-unstyled flex-column mx-auto'
                  >
                    {['amazon', 'shipstation', 'shopify', 'walmart', 'ebay', 'tiktok'].map(
                      (platform) => (
                        <li key={platform}>
                          <NavLink
                            to={`/integrations/${platform}`}
                            className='nav-link'
                            onClick={toggleMobileIntegrations}
                          >
                            {platform.charAt(0).toUpperCase() + platform.slice(1)}
                          </NavLink>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </li>

              {/* Desktop Links */}
              <li className='nav-item d-none d-md-block'>
                <NavLink to={'/product'} className='nav-link py-2 py-md-0 px-0'>
                  {intl.formatMessage({id: 'WHATS_SHIPBAE'})}
                </NavLink>
              </li>

              <li className='nav-item d-none d-md-block'>
                <NavLink to={'/pricing'} className='nav-link py-2 py-md-0 px-0'>
                  {intl.formatMessage({id: 'PRICING'})}
                </NavLink>
              </li>

              {/* Desktop Integrations */}
              <li className='nav-item d-none d-md-block integrations-container position-relative'>
                <button
                  className='nav-link py-2 py-md-0 px-0 btn btn-link p-0'
                  onClick={toggleIntegrations}
                >
                  {intl.formatMessage({id: 'INTEGRATION'})}
                  <img
                    className='ms-2 d-inline-block'
                    src='/media/gori/arrows/arrow_down_gray.svg'
                    alt='down-chevron'
                  />
                </button>

                <ul
                  id='integrations-dropdown-desktop'
                  className='integration-dropdown-menu d-none nav flex-nowrap list-unstyled flex-column position-absolute w-100 bg-white border rounded shadow-sm p-5 z-3'
                >
                  {['amazon', 'shipstation', 'shopify', 'walmart', 'ebay', 'tiktok'].map(
                    (platform) => (
                      <li key={platform}>
                        <NavLink
                          to={`/integrations/${platform}`}
                          className='nav-link'
                          onClick={toggleIntegrations}
                        >
                          {platform.charAt(0).toUpperCase() + platform.slice(1)}
                        </NavLink>
                      </li>
                    )
                  )}
                </ul>
              </li>
            </ul>

            {/* Right Side Buttons */}
            <ul className='navbar-nav align-items-center mb-2 mb-md-0'>
              <li className='nav-item py-2 py-md-0'>
                <NavLink
                  to={ROUTES.AUTH.BASE}
                  className='btn btn-outline text-primary btn-active-light-primary darker fs-4 fw-boldest text-capitalize rounded-pill py-3 px-6'
                >
                  {intl.formatMessage({id: 'LOG_IN'})}
                </NavLink>
              </li>

              <li className='nav-item ms-md-3 mt-2 mt-md-0 py-2 py-md-0'>
                <a
                  className='btn btn-outline btn-primary darker hover-orange fs-4 fw-boldest text-capitalize rounded-pill py-3 px-6'
                  href={`${document.location.protocol}//${BASE_DOMAIN}${ROUTES.AUTH.REGISTRATION}`}
                >
                  {intl.formatMessage({id: 'START_FREE'})}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
